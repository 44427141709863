import axios from 'axios';
import { GET_TWITTER, API_HOST } from './const';

const API_URL = '/api/Social/GetTwitter/';

export default (seconds, userId, take) => {
  const url = `${API_HOST}${API_URL}?seconds=${seconds}&userId=${userId}&take=${take}`;
  const request = axios.get(url);

  return {
    type: GET_TWITTER,
    payload: request,
  };
};
