import axios from 'axios';
import { GET_FACEBOOK, API_HOST } from './const';

const API_URL = '/api/Social/GetFacebook/';

export default (seconds, identifier, take) => {
  const url = `${API_HOST}${API_URL}?seconds=${seconds}&identifier=${identifier}&take=${take}`;
  const request = axios.get(url);

  return {
    type: GET_FACEBOOK,
    payload: request,
  };
};
