const labels = {
  da: {
    AUonSoMe: 'AU på sociale medier',
    followUs: 'Følg os på',
  },
  en: {
    AUonSoMe: 'AU on social media',
    followUs: 'Follow us',
  },
};

export default labels;
