import { GET_FACEBOOK, GET_TWITTER } from '../actions/const';

const initialState = {
  Facebook: {
    Posts: [],
    AccessToken: '',
  },
  Twitter: [],
  loaded: false,
};

export default (state = initialState, action) => {
  const nextState = { ...state };
  switch (action.type) {
    case GET_FACEBOOK: {
      const { data } = action.payload;
      nextState.Facebook.Posts = data.Data;
      nextState.Facebook.AccessToken = data.AccessToken;
      nextState.loaded = true;
      return nextState;
    }

    case GET_TWITTER: {
      const { data } = action.payload;
      nextState.Twitter = data.Data;
      nextState.loaded = true;
      return nextState;
    }

    default: {
      return state;
    }
  }
};
