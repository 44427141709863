import React from 'react';
import PropTypes from 'prop-types';

const TwitterStatusComponent = (props) => {
  const { status, convertDate, highlight } = props;
  return (
    <div className="tw-status">
      {/* eslint-disable react/no-danger */}
      <div dangerouslySetInnerHTML={{ __html: `<p>${highlight(status.Message, 'https://twitter.com/')}</p>` }} />
      <div dangerouslySetInnerHTML={{ __html: `<p>${highlight(status.User, 'https://twitter.com/')} ${convertDate(status.Created)}</p>` }} />
      {/* eslint-enable react/no-danger */}
      <div className="stats">
        <span className="likes">{status.Likes}</span>
        <span className="retweets">{status.Retweets}</span>
      </div>
    </div>
  );
};

TwitterStatusComponent.propTypes = {
  status: PropTypes.shape({
    Created: PropTypes.string.isRequired,
    ID: PropTypes.string.isRequired,
    Likes: PropTypes.number.isRequired,
    Message: PropTypes.string.isRequired,
    Retweets: PropTypes.number.isRequired,
    User: PropTypes.string.isRequired,
  }).isRequired,
  convertDate: PropTypes.func.isRequired,
  highlight: PropTypes.func.isRequired,
};

TwitterStatusComponent.displayName = 'TwitterStatusComponent';
export default TwitterStatusComponent;
